import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useStickyHeader(offset = 0) {
  const [stick, setStick] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    setStick(window.scrollY > offset);
  };

  useLayoutEffect(() => {
    if (location.pathname.includes("/videos")) {
      return setStick(true);
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return stick;
}
