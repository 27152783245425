import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem, Zoom } from "@mui/material";

import { useAppSelector } from "../../../app/hooks";
import logoImg from "../../../assets/svg/aidentic-white-logo.svg";
import logoGrayImg from "../../../assets/svg/aidentis-gray-logo.png";
import LangSwitcher from "../../../components/LangSwitcher";
import PATH from "../../../helpers/PATH";

import { PageHeaderWrapper } from "./styles";
import { useStickyHeader } from "./useStickyHeader";

interface IProps {
  mode?: "dark" | "light";
  isMenuActive: boolean;
  setIsMenuActive: (v: boolean) => void;
}

const PageHeader: React.FC<IProps> = ({
  mode = "dark",
  isMenuActive,
  setIsMenuActive,
}) => {
  const { hash } = useLocation();
  const token = useAppSelector((state) => state.userSlice.token);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const [productsAnchorEl, setProductsAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const productsMenuOpen = Boolean(productsAnchorEl);
  const sticky = useStickyHeader(50);
  const headerClasses = `${sticky ? "sticky" : ""}`;

  useEffect(() => {
    let anchorElement: HTMLDivElement | null = null;
    if (hash) {
      anchorElement = document.querySelector(hash);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }

    return () => {
      if (anchorElement) anchorElement.remove();
    };
  }, [hash]);

  const handleProductsMenuClose = () => {
    setProductsAnchorEl(null);
  };

  const handleProductsMenuItemClick = () => {
    handleProductsMenuClose();
    navigate(PATH.CEPHALOMETRIC_ANALYSIS);
  };

  const handleProductsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setProductsAnchorEl(event.currentTarget);
  };

  return (
    <PageHeaderWrapper className={headerClasses}>
      <div className="header-container">
        <div className="header">
          <Link className="logo" to={PATH.HOME}>
            <img
              alt="AIDentis"
              className="logo"
              src={sticky ? logoGrayImg : logoImg}
            />
          </Link>
          <button
            className="menu-btn"
            type="button"
            onClick={() => setIsMenuActive(true)}
          >
            <svg fill="none" height="18" viewBox="0 0 32 18" width="32">
              <path d="M32 17L0 17" stroke="white" strokeWidth="2" />
              <path d="M0 1H32" stroke="white" strokeWidth="2" />
              <path d="M32 9H0" stroke="white" strokeWidth="2" />
            </svg>
          </button>
          <menu>
            <li>
              {/*<NavLink to={PATH.CEPHALOMETRIC_ANALYSIS}>*/}
              {/*  {t("product_other")}*/}
              {/*</NavLink>*/}
              <a onClick={handleProductsMenuClick}>{t("product_other")}</a>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive && hash === "#price" ? "active" : undefined
                }
                to={{ pathname: PATH.HOME, hash: "price" }}
              >
                {t("price")}
              </NavLink>
            </li>
            <li>
              <Link to={PATH.VIDEOS}>{t("page:video")}</Link>
            </li>
            <li>
              <Link to={PATH.APP_BOOK_A_DEMO}>{t("bookDemo")}</Link>
            </li>
            <li>
              <Link to={token ? PATH.APP_CASES : PATH.APP_SIGNIN}>
                <span>{t("signIn")}</span>
              </Link>
            </li>
            <li>
              <LangSwitcher mode={sticky ? "dark" : "light"} />
            </li>
            {/*<li>*/}
            {/*  <Link to={PATH.APP_TARIFFS}>*/}
            {/*    <button className="tryFree" type="button">*/}
            {/*      {t("page:try", { context: "free" })}*/}
            {/*    </button>*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </menu>
        </div>
      </div>
      <Menu
        anchorEl={productsAnchorEl}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        open={productsMenuOpen}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: "50%",
              width: 10,
              height: 10,
              bgcolor: "rgb(255,255,255)",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            "& .MuiMenuItem-root:hover": {
              backgroundColor: mode === "dark" ? "grey.200" : "#253041",
            },
            backgroundColor: mode === "light" ? "rgba(20, 29, 44, 1)" : "#fff",
            color: mode === "light" ? "#fff" : "#000",
            "@media screen and (max-width: 1400px)": {
              ".MuiButtonBase-root": {
                fontSize: 12,
              },
            },
          },
        }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Zoom}
        onClose={handleProductsMenuClose}
      >
        <MenuItem onClick={handleProductsMenuItemClick}>
          {t("page:ourSolutions", { context: "title1" }) || ""}
        </MenuItem>
        <MenuItem onClick={handleProductsMenuItemClick}>
          {t("page:ourSolutions", { context: "title2" }) || ""}
        </MenuItem>
        <MenuItem onClick={handleProductsMenuItemClick}>
          {t("page:ourSolutions", { context: "title3" }) || ""}
        </MenuItem>
      </Menu>
    </PageHeaderWrapper>
  );
};

export default PageHeader;
