import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { useAppSelector } from "../../app/hooks";
import video from "../../assets/AIDENTIS_no_logo.mp4";
import aiImage from "../../assets/images/page/ai-img.png";
import reportImg from "../../assets/images/page/report-img.png";
import xRayImg from "../../assets/images/page/x-ray-img.png";
import slide1 from "../../assets/images/page-carousel/1.png";
import slide2 from "../../assets/images/page-carousel/2.png";
import slide3 from "../../assets/images/page-carousel/3.png";
import slide4 from "../../assets/images/page-carousel/4.png";
import slide5 from "../../assets/images/page-carousel/5.png";
import slide6 from "../../assets/images/page-carousel/6.png";
import slide7 from "../../assets/images/page-carousel/7.png";
import PATH from "../../helpers/PATH";

import { WelcomeWrapper } from "./styles";

function SampleNextArrow(props: React.HTMLProps<HTMLButtonElement>) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <svg fill="none" viewBox="0 0 66 66">
        <path
          d="M45.6109 34.0608C46.1966 33.475 46.1966 32.5252 45.6109 31.9395L36.0649 22.3935C35.4791 21.8077 34.5294 21.8077 33.9436 22.3935C33.3578 22.9793 33.3578 23.9291 33.9436 24.5148L42.4289 33.0001L33.9436 41.4854C33.3578 42.0712 33.3578 43.0209 33.9436 43.6067C34.5294 44.1925 35.4791 44.1925 36.0649 43.6067L45.6109 34.0608ZM21.4502 34.5001H44.5502V31.5001H21.4502V34.5001Z"
          fill="white"
        />
      </svg>
    </button>
  );
}

function SamplePrevArrow(props: React.HTMLProps<HTMLButtonElement>) {
  const { className, style, onClick } = props;
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <svg fill="none" viewBox="0 0 66 66">
        <path
          d="M20.3891 34.0608C19.8034 33.475 19.8034 32.5252 20.3891 31.9395L29.9351 22.3935C30.5209 21.8077 31.4706 21.8077 32.0564 22.3935C32.6422 22.9793 32.6422 23.9291 32.0564 24.5148L23.5711 33.0001L32.0564 41.4854C32.6422 42.0712 32.6422 43.0209 32.0564 43.6067C31.4706 44.1925 30.5209 44.1925 29.9351 43.6067L20.3891 34.0608ZM44.5498 34.5001H21.4498V31.5001H44.5498V34.5001Z"
          fill="white"
        />
      </svg>
    </button>
  );
}

const Welcome = () => {
  const [activeServiceTab, setActiveServiceTab] = useState(0);
  const token = useAppSelector((state) => state.userSlice.token);
  const { t } = useTranslation("page");

  const slickSettings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    dots: true,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow className="button" />,
    prevArrow: <SamplePrevArrow className="button" />,
  };

  return (
    <WelcomeWrapper>
      <div className="main">
        <div className="header-main">
          <section className="section-main">
            <div className="container">
              <div className="section-main__inner">
                <h1 className="section-main__title2">
                  <Trans i18nKey="page:title">
                    Помощь Стоматологическим Клиникам <br /> для укрепления
                    доверия пациентов <br /> с помощью Искусственного Интеллекта
                  </Trans>
                </h1>
                <p className="section-main__text">{t("goal1")}</p>
                <Link
                  className="section-main__link link-all"
                  to={token ? PATH.APP_TARIFFS : PATH.APP_SIGNUP}
                >
                  {t("try", { context: "free" })}
                </Link>
              </div>
            </div>
          </section>
        </div>
        <section className="services">
          <div className="container">
            <div className="services__inner">
              <h2 className="title2">{t("ourSolutions")}</h2>
              <div className="services__items">
                <div
                  className="services__item-wrap"
                  onClick={() =>
                    activeServiceTab === 1
                      ? setActiveServiceTab(0)
                      : setActiveServiceTab(1)
                  }
                >
                  <div
                    className={`services__item ${
                      activeServiceTab === 1 ? "active" : ""
                    }`}
                    onClick={() => setActiveServiceTab(1)}
                  >
                    <h3 className="services__item-title2">
                      {t("ourSolutions", { context: "title2" })}
                    </h3>
                    <p className="services__item-text">
                      {t("ourSolutions", { context: "text2" })}
                    </p>
                  </div>
                  <div
                    className={`services__item-imgs ${
                      activeServiceTab === 1 ? "active" : ""
                    }`}
                  >
                    <div>
                      <img alt="aidentis" src={xRayImg} />
                      <p>{t("ourSolutions", { context: "title2-stage1" })}</p>
                    </div>
                    <div>
                      <img alt="aidentis" src={aiImage} />
                      <p>
                        <Trans
                          context="title2-stage2"
                          i18nKey="page:ourSolutions"
                        >
                          Этап 2 - анализ с помощью ИИ <br /> в считанные минуты
                        </Trans>
                      </p>
                    </div>
                    <div>
                      <img alt="aidentis" src={reportImg} />
                      <p>{t("ourSolutions", { context: "title2-stage3" })}</p>
                    </div>
                  </div>
                </div>
                <div className="services__item-wrap services__item--decor">
                  <div className="services__item">
                    <h3 className="services__item-title2">
                      {t("ourSolutions", { context: "title1" })}
                    </h3>
                    <p className="services__item-text">
                      {t("ourSolutions", { context: "text1" })}
                    </p>
                  </div>
                </div>
                <div className="services__item-wrap">
                  <div className="services__item">
                    <h3 className="services__item-title2">
                      {t("ourSolutions", { context: "title3" })}
                    </h3>
                    <p className="services__item-text">
                      {t("ourSolutions", { context: "text3" })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="video">
          <video
            style={{
              // position: "absolute",
              // top: "0",
              // left: "0",
              // right: "0",
              // bottom: "0",
              width: "100%",
              height: "auto",
            }}
            autoPlay
            loop
            muted
          >
            <source src={video} type="video/mp4" />
          </video>
        </section>
        <section className="presentation">
          <div className="container">
            <h2 className="title2 presentation__title2">{t("presentation")}</h2>
            <div className="presentation__info">
              <div className="presentation__descr">
                <div className="presentation__about">
                  <h3 className="presentation__title2">{t("presentation1")}</h3>
                  <p className="presentation__text">
                    <Trans i18nKey="page:presentation2">
                      <span>Структурированная информация для удобства</span>
                      <span>Персонализированный дизайн</span>
                      <span>Сохранение презентации на ваше устройство</span>
                    </Trans>
                  </p>
                  <Link
                    className="presentation__link link-all"
                    to={PATH.APP_BOOK_A_DEMO}
                  >
                    {t("request", { context: "demo" })}
                  </Link>
                </div>
              </div>
              <div className="presentation__slide">
                <Slider {...slickSettings}>
                  <li>
                    <img alt="slide" src={slide1} />
                  </li>
                  <li>
                    <img alt="slide" src={slide2} />
                  </li>
                  <li>
                    <img alt="slide" src={slide3} />
                  </li>
                  <li>
                    <img alt="slide" src={slide4} />
                  </li>
                  <li>
                    <img alt="slide" src={slide5} />
                  </li>
                  <li>
                    <img alt="slide" src={slide6} />
                  </li>
                  <li>
                    <img alt="slide" src={slide7} />
                  </li>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        <section className="prices">
          <div className="container prices-container">
            <div className="mission">
              <div className="mission__about">
                <h2 className="mission__title2">{t("mission")}</h2>
                <p className="mission__descr">{t("mission2")}</p>
              </div>
              <div className="mission__items">
                <div className="mission__item">
                  <h3 className="mission__item-title2">{t("tech")}</h3>
                  <p className="mission__item-text">{t("mission3")}</p>
                </div>
                <div className="mission__item">
                  <h3 className="mission__item-title2">{t("effect")}</h3>
                  <p className="mission__item-text">{t("mission4")}</p>
                </div>
                <div className="mission__item">
                  <h3 className="mission__item-title2">{t("care")}</h3>
                  <p className="mission__item-text">{t("mission5")}</p>
                </div>
              </div>
            </div>
            <div className="prices-cards" id="price">
              <h2 className="prices__title2">{t("pr")}</h2>
              <div className="prices__cards">
                <div className="prices__card-wrap">
                  <div className="prices__card">
                    <p className="prices__card-date">
                      {t("day_suffix", { suffix: "7" })}
                    </p>
                    <p className="prices__card-price">
                      0 ₸
                      <span className="prices__card-span">
                        {t("week", { context: "of" })}
                      </span>
                    </p>
                    <p className="prices__card-descr">
                      {/*<span>50</span> {t("ceph1") + " "}*/}
                      {/*<span>200 ₸</span> {t("each1")}*/}
                      {/*{t("ceph1") + " "}*/}
                      {/*{t("each1")}*/}
                      {t("tariffDesc1")}
                    </p>
                    <Link
                      className="prices__card-link"
                      to={token ? PATH.APP_TARIFFS : PATH.APP_SIGNUP}
                    >
                      {t("choose")}
                    </Link>
                  </div>
                </div>
                <div className="prices__card-wrap">
                  <div className="prices__card">
                    <p className="prices__card-date">{t("month_one")}</p>
                    <p className="prices__card-price">19,990 ₸</p>
                    <p className="prices__card-descr">
                      {/*<span>50</span> {t("ceph1") + " "}*/}
                      {/*<span>200 ₸</span> {t("each1")}*/}
                      {/*{t("ceph1") + " "}*/}
                      {/*{t("each1")}*/}
                      {t("tariffDesc1")}
                    </p>
                    <Link
                      className="prices__card-link"
                      to={token ? PATH.APP_TARIFFS : PATH.APP_SIGNUP}
                    >
                      {t("choose")}
                    </Link>
                  </div>
                </div>
                <div className="prices__card-wrap">
                  <div className="prices__card">
                    <p className="prices__card-date">
                      {t("month", { context: "six" })}
                    </p>
                    <p className="prices__card-price">
                      99,990 ₸
                      <span className="prices__card-span">
                        {/*{t("month", { context: "in" })}*/}
                      </span>
                    </p>
                    <p className="prices__card-descr">
                      {/*<span>300</span> {t("ceph1") + " "}*/}
                      {/*<span>170 ₸</span> {t("each1")}*/}
                      {/*{t("ceph1") + " "}*/}
                      {/*{t("each1")}*/}
                      {t("tariffDesc2")}
                    </p>
                    <Link
                      className="prices__card-link"
                      to={token ? PATH.APP_TARIFFS : PATH.APP_SIGNUP}
                    >
                      {t("choose")}
                    </Link>
                  </div>
                </div>
                <div className="prices__card-wrap">
                  <div className="prices__card">
                    <p className="prices__card-date">
                      {t("month", { context: "twelve" })}
                    </p>
                    <p className="prices__card-price">
                      199,990 ₸
                      <span className="prices__card-span">
                        {/*{t("month", { context: "in" })}*/}
                      </span>
                      <span></span>
                    </p>
                    <p className="prices__card-descr">
                      {/*<span>600</span> {t("ceph1") + " "}*/}
                      {/*<span>140 ₸</span> {t("each1")}*/}
                      {/*{t("ceph1") + " "}*/}
                      {/*{t("each1")}*/}
                      {t("tariffDesc3")}
                    </p>
                    <Link
                      className="prices__card-link"
                      to={token ? PATH.APP_TARIFFS : PATH.APP_SIGNUP}
                    >
                      {t("choose")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </WelcomeWrapper>
  );
};

export default Welcome;
