import React, { lazy, Suspense } from "react";
import cookies from "react-cookies";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useAppSelector } from "./app/hooks";
import { useGetCurrentUserQuery } from "./app/user/api";
import EmptyBox from "./components/EmptyBox";
import Fallback from "./components/Fallback";
import { COOKIES } from "./helpers/constants";
import PATH from "./helpers/PATH";
import AppLayout from "./layouts/App";
import AppBodyLayout from "./layouts/AppBody";
import AuthLayout from "./layouts/Auth";
import PageLayout from "./layouts/Page";
import PrivacyLayout from "./layouts/Privacy";
import BookADemo from "./views/BookADemo";
import CephalometricAnalysis from "./views/CephalometricAnalysis";
import ForgotPassword from "./views/ForgotPassword";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";
import Welcome from "./views/Welcome";

import "./App.scss";

const Cases = lazy(() => import("./views/Cases"));
const Case = lazy(() => import("./views/Case"));
const Tariffs = lazy(() => import("./views/Tariffs"));
const PaymentProfile = lazy(() => import("./views/PaymentProfile"));
const Privacy = lazy(() => import("./views/Privacy"));
const Results = lazy(() => import("./views/Results"));
const Videos = lazy(() => import("./views/Videos"));

const AppAuthRoutes = () => {
  const token = useAppSelector((state) => state.userSlice.token);
  const location = useLocation();
  useGetCurrentUserQuery(null, { skip: !token });

  if (
    !token &&
    location.pathname !== PATH.HOME &&
    location.pathname !== PATH.CEPHALOMETRIC_ANALYSIS
  ) {
    if (
      !cookies.load(COOKIES.REDIRECT_URL) &&
      !location.pathname.includes(PATH.APP_SIGNIN)
    ) {
      cookies.save(
        COOKIES.REDIRECT_URL,
        `${location.pathname}${location.search}`,
        { path: "/" }
      );
    }
    return <Route element={<Navigate to={PATH.APP_SIGNIN} />} path="*" />;
  }

  return (
    <>
      <Route element={<AppBodyLayout />}>
        <Route path="cases">
          <Route element={<Cases />} index />
          <Route element={<Case />} path={PATH.CASE + "/*"} />
          <Route element={<EmptyBox />} path="*" />
          <Route element={<Results />} path=":id/results" />
        </Route>
        <Route element={<PaymentProfile />} path="payment-profile" />
        <Route element={<Tariffs />} path="tariffs" />
      </Route>
    </>
  );
};

function App() {
  return (
    <Suspense fallback={<Fallback />}>
      <Routes>
        <Route element={<PageLayout />} path="/">
          <Route element={<Welcome />} index />
          <Route element={<Videos />} path="videos" />
          <Route
            element={<CephalometricAnalysis />}
            path="cephalometric-analysis"
          />
        </Route>
        <Route element={<AppLayout />} path="/app">
          <Route element={<AuthLayout />} path="auth">
            <Route element={<SignIn />} index />
            <Route element={<SignUp />} path="sign-up" />
            <Route element={<BookADemo />} path="book-a-demo" />
            <Route element={<ForgotPassword />} path="forgot-password" />
          </Route>
          <Route element={<PrivacyLayout />} path="privacy">
            <Route element={<Privacy />} path=":countryCode/:type" />
          </Route>
          {AppAuthRoutes()}
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
