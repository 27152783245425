import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";

import mastercardIcon from "../../../assets/svg/mastercard.svg";
import visaIcon from "../../../assets/svg/visa.svg";
import PATH from "../../../helpers/PATH";
import { ECountryCode } from "../../../types/Common";
import { EPrivacyType } from "../../../types/Privacy";

import { PageFooterWrapper } from "./styles";

const PageFooter = () => {
  const { t } = useTranslation("common");

  const onSubmit = (e: any) => {
    console.log(e);
  };

  return (
    <PageFooterWrapper>
      <div className="container">
        <div className="footer">
          <div className="footer-left">
            <h2>{t("getInTouchWithUs")}</h2>
            <a className="contact-link" href="mailto:EMAIL: info@AIDENTIS.COM">
              <svg fill="none" height="36" viewBox="0 0 36 36" width="36">
                <path
                  d="M17.9042 35.9999C14.3607 35.9954 10.8979 34.9408 7.95335 32.9694C5.00881 30.9981 2.71459 28.1983 1.36049 24.9238C0.0063918 21.6493 -0.346842 18.047 0.345408 14.5718C1.03766 11.0967 2.74434 7.90466 5.24987 5.39893C6.90732 3.70249 8.88495 2.3518 11.0682 1.4251C13.2514 0.498399 15.5968 0.0141149 17.9686 0.000303977C20.3403 -0.013507 22.6912 0.443429 24.8851 1.34464C27.079 2.24585 29.0722 3.57342 30.7493 5.25044C32.4264 6.92746 33.754 8.92058 34.6553 11.1143C35.5565 13.3081 36.0135 15.6589 35.9997 18.0305C35.9859 20.4021 35.5016 22.7474 34.5748 24.9306C33.6481 27.1137 32.2973 29.0912 30.6008 30.7486C28.9377 32.421 26.9589 33.7464 24.7794 34.6479C22.5998 35.5494 20.2629 36.0089 17.9042 35.9999ZM17.9042 1.19367C14.5672 1.19367 11.3051 2.18317 8.53039 4.03705C5.75572 5.89093 3.59313 8.52592 2.31609 11.6088C1.03905 14.6917 0.704915 18.084 1.35594 21.3568C2.00697 24.6296 3.61392 27.6358 5.97358 29.9954C8.33325 32.3549 11.3396 33.9618 14.6126 34.6128C17.8855 35.2638 21.278 34.9297 24.3611 33.6527C27.4441 32.3757 30.0792 30.2132 31.9332 27.4387C33.7872 24.6642 34.7767 21.4022 34.7767 18.0653C34.7701 13.5927 32.9903 9.30525 29.8275 6.14265C26.6647 2.98006 22.3771 1.20036 17.9042 1.19367Z"
                  fill="#BADAFF"
                />
                <path
                  d="M10.6056 12.2852L18.0044 18.7037L25.3987 12.2852H10.6056Z"
                  fill="#BADAFF"
                />
                <path
                  d="M18.355 19.881C18.3115 19.9185 18.2625 19.9487 18.2098 19.9703H18.1735C18.121 19.9903 18.0659 20.0014 18.0101 20.0032C17.9542 20.0014 17.8991 19.9903 17.8466 19.9703H17.8149C17.7603 19.9499 17.7096 19.9196 17.6651 19.881L9.81689 13.0957V23.7321H26.1805V13.0957L18.355 19.881Z"
                  fill="#BADAFF"
                />
              </svg>
              <span>EMAIL: info@AIDENTIS.COM</span>
            </a>
            <h2 style={{ marginBottom: "18px" }}>{t("socials")}</h2>
            <ul>
              <li>
                <a
                  href="https://instagram.com/ai.dentis?igshid=cHQzNWs4Y3A3cjc3"
                  target="_blank"
                >
                  <svg fill="none" height="36" viewBox="0 0 36 36" width="36">
                    <path
                      d="M17.9042 35.9999C14.3607 35.9954 10.8979 34.9408 7.95335 32.9694C5.00881 30.9981 2.71459 28.1983 1.36049 24.9238C0.0063918 21.6493 -0.346842 18.047 0.345408 14.5718C1.03766 11.0967 2.74434 7.90466 5.24987 5.39893C6.90732 3.70249 8.88495 2.3518 11.0682 1.4251C13.2514 0.498399 15.5968 0.0141149 17.9686 0.000303977C20.3403 -0.013507 22.6912 0.443429 24.8851 1.34464C27.079 2.24585 29.0722 3.57342 30.7493 5.25044C32.4264 6.92746 33.754 8.92058 34.6553 11.1143C35.5565 13.3081 36.0135 15.6589 35.9997 18.0305C35.9859 20.4021 35.5016 22.7474 34.5748 24.9306C33.6481 27.1137 32.2973 29.0912 30.6008 30.7486C28.9377 32.421 26.9589 33.7464 24.7794 34.6479C22.5998 35.5494 20.2629 36.0089 17.9042 35.9999ZM17.9042 1.19367C14.5672 1.19367 11.3051 2.18317 8.53039 4.03705C5.75572 5.89093 3.59313 8.52592 2.31609 11.6088C1.03905 14.6917 0.704915 18.084 1.35594 21.3568C2.00697 24.6296 3.61392 27.6358 5.97358 29.9954C8.33325 32.3549 11.3396 33.9618 14.6126 34.6128C17.8855 35.2638 21.278 34.9297 24.3611 33.6527C27.4441 32.3757 30.0792 30.2132 31.9332 27.4387C33.7872 24.6642 34.7767 21.4022 34.7767 18.0653C34.7701 13.5927 32.9903 9.30525 29.8275 6.14265C26.6647 2.98006 22.3771 1.20036 17.9042 1.19367Z"
                      fill="#BADAFF"
                    />
                    <path
                      d="M21.8411 27.5138H13.9701C12.4867 27.5126 11.0642 26.9232 10.0149 25.8746C8.9655 24.8261 8.37491 23.4042 8.37268 21.9208V13.5187C8.37491 12.0353 8.9655 10.6134 10.0149 9.56489C11.0642 8.51638 12.4867 7.9269 13.9701 7.92578H21.8411C23.3243 7.92801 24.7461 8.51785 25.7952 9.56612C26.8443 10.6144 27.4352 12.0357 27.4386 13.5187V21.9208C27.4352 23.4038 26.8443 24.8251 25.7952 25.8734C24.7461 26.9217 23.3243 27.5115 21.8411 27.5138ZM13.9701 9.37674C12.867 9.37786 11.8094 9.81624 11.029 10.5958C10.2486 11.3754 9.80907 12.4326 9.80684 13.5356V21.9208C9.80907 23.0238 10.2486 24.081 11.029 24.8606C11.8094 25.6402 12.867 26.0785 13.9701 26.0797H21.8411C22.9439 26.0785 24.0011 25.64 24.7808 24.8603C25.5606 24.0806 25.9991 23.0235 26.0002 21.9208V13.5187C25.9991 12.4161 25.5606 11.3589 24.7808 10.5792C24.0011 9.79951 22.9439 9.36099 21.8411 9.35987L13.9701 9.37674Z"
                      fill="#BADAFF"
                    />
                    <path
                      d="M17.9086 22.8966C16.8847 22.8917 15.8848 22.5861 15.033 22.0179C14.1813 21.4496 13.5152 20.6437 13.1176 19.7002C12.72 18.7567 12.6082 17.7171 12.7963 16.7107C12.9844 15.7042 13.464 14.7752 14.1756 14.039C14.641 13.5493 15.201 13.1592 15.8217 12.8924C16.4423 12.6256 17.1107 12.4876 17.7863 12.4868H17.9339C18.6557 12.4775 19.3719 12.6135 20.04 12.8867C20.7082 13.1599 21.3145 13.5646 21.823 14.0769C22.7185 15.073 23.1827 16.3835 23.1138 17.7212C23.0908 19.0938 22.5376 20.4043 21.5699 21.3781C21.0916 21.8624 20.5213 22.2462 19.8926 22.5069C19.2638 22.7677 18.5893 22.9002 17.9086 22.8966ZM17.7863 14.2372C17.3406 14.2432 16.9005 14.3375 16.4914 14.5145C16.0824 14.6915 15.7125 14.9479 15.403 15.2687C15.0936 15.5895 14.8508 15.9684 14.6886 16.3835C14.5264 16.7987 14.4481 17.2419 14.4582 17.6875C14.4582 18.6025 14.8217 19.4801 15.4688 20.1272C16.1159 20.7742 16.9935 21.1377 17.9086 21.1377C18.8237 21.1377 19.7013 20.7742 20.3484 20.1272C20.9955 19.4801 21.359 18.6025 21.359 17.6875C21.4054 15.6038 20.0936 14.3131 17.8833 14.2541L17.7863 14.2372Z"
                      fill="#BADAFF"
                    />
                    <path
                      d="M24.4439 12.3356C24.4447 12.5764 24.374 12.812 24.2406 13.0125C24.1073 13.213 23.9173 13.3693 23.6949 13.4617C23.4726 13.554 23.2277 13.5782 22.9916 13.5311C22.7554 13.484 22.5386 13.3678 22.3686 13.1972C22.1986 13.0267 22.0832 12.8094 22.0369 12.5731C21.9906 12.3368 22.0157 12.0921 22.1088 11.87C22.2019 11.648 22.3589 11.4586 22.5599 11.3259C22.7609 11.1933 22.9967 11.1234 23.2375 11.125C23.5579 11.1261 23.8647 11.2542 24.0908 11.4811C24.3169 11.708 24.4439 12.0152 24.4439 12.3356Z"
                      fill="#BADAFF"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#twitter">
                  <svg fill="none" height="36" viewBox="0 0 36 36" width="36">
                    <path
                      d="M17.9986 35.9999C14.4387 36.0004 10.9586 34.9449 7.99856 32.967C5.03854 30.9891 2.73155 28.1776 1.36944 24.8882C0.00733459 21.5988 -0.348702 17.9792 0.346373 14.4874C1.04145 10.9956 2.7564 7.78837 5.2743 5.27149C8.65011 1.89581 13.2285 -0.000397036 18.0022 6.23569e-08C22.7759 0.000397161 27.3539 1.89737 30.7292 5.2736C34.1044 8.64984 36.0004 13.2288 36 18.0031C35.9996 22.7774 34.1029 27.356 30.7271 30.7317C29.0592 32.4081 27.0754 33.7369 24.8905 34.6412C22.7057 35.5455 20.3631 36.0073 17.9986 35.9999ZM17.9986 1.04929C14.6487 1.04929 11.374 2.04278 8.58862 3.90413C5.80327 5.76547 3.63235 8.41108 2.35039 11.5064C1.06844 14.6017 0.73302 18.0077 1.38656 21.2936C2.04009 24.5796 3.65323 27.5979 6.02198 29.967C8.39072 32.336 11.4087 33.9494 14.6942 34.603C17.9798 35.2566 21.3853 34.9212 24.4803 33.639C27.5752 32.3569 30.2204 30.1857 32.0815 27.4C33.9427 24.6143 34.936 21.3392 34.936 17.9889C34.9282 13.4986 33.1412 9.19453 29.9665 6.01944C26.7918 2.84435 22.4883 1.05713 17.9986 1.04929Z"
                      fill="#BADAFF"
                    />
                    <path
                      d="M18.3122 15.2737C17.5712 11.7545 21.9877 8.95944 24.7993 11.8603C24.7993 11.8603 26.6497 11.403 27.2256 10.9117C27.2256 10.9117 27.2256 12.017 25.625 12.9614C26.3612 12.9232 27.0818 12.7344 27.7422 12.4066C27.3663 13.2493 26.7091 13.935 25.8833 14.3462C25.914 15.8969 25.6356 17.4382 25.0641 18.8801C24.4927 20.322 23.6397 21.6355 22.5551 22.744C20.7368 24.5882 18.2717 25.6515 15.6827 25.7085C13.3908 25.7652 11.1316 25.1562 9.17871 23.9552C9.17871 23.9552 12.4942 24.4253 14.7554 22.4349C13.9626 22.4274 13.1935 22.1641 12.5626 21.6841C11.9316 21.2041 11.4725 20.5332 11.2535 19.7711C11.2535 19.7711 12.363 19.9871 12.905 19.7288C12.905 19.7288 9.8308 18.8183 9.97477 15.8581C9.97477 15.8581 10.3982 16.3705 11.5542 16.3705C11.5542 16.3705 8.92042 14.2954 10.4448 11.3267C12.3895 13.5929 15.1398 15.013 18.1132 15.2864L18.3122 15.2737Z"
                      fill="#BADAFF"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#linkedin">
                  <svg fill="none" height="36" viewBox="0 0 36 36" width="36">
                    <path
                      d="M17.9042 35.9999C14.3607 35.9954 10.8979 34.9408 7.95335 32.9694C5.00881 30.9981 2.71459 28.1983 1.36049 24.9238C0.0063918 21.6493 -0.346842 18.047 0.345408 14.5718C1.03766 11.0967 2.74434 7.90466 5.24987 5.39893C6.90732 3.70249 8.88495 2.3518 11.0682 1.4251C13.2514 0.498399 15.5968 0.0141149 17.9686 0.000303977C20.3403 -0.013507 22.6912 0.443429 24.8851 1.34464C27.079 2.24585 29.0722 3.57342 30.7493 5.25044C32.4264 6.92746 33.754 8.92058 34.6553 11.1143C35.5565 13.3081 36.0135 15.6589 35.9997 18.0305C35.9859 20.4021 35.5016 22.7474 34.5748 24.9306C33.6481 27.1137 32.2973 29.0912 30.6008 30.7486C28.9377 32.421 26.9589 33.7464 24.7794 34.6479C22.5998 35.5494 20.2629 36.0089 17.9042 35.9999ZM17.9042 1.19367C14.5672 1.19367 11.3051 2.18317 8.53039 4.03705C5.75572 5.89093 3.59313 8.52592 2.31609 11.6088C1.03905 14.6917 0.704915 18.084 1.35594 21.3568C2.00697 24.6296 3.61392 27.6358 5.97358 29.9954C8.33325 32.3549 11.3396 33.9618 14.6126 34.6128C17.8855 35.2638 21.278 34.9297 24.3611 33.6527C27.4441 32.3757 30.0792 30.2132 31.9332 27.4387C33.7872 24.6642 34.7767 21.4022 34.7767 18.0653C34.7701 13.5927 32.9903 9.30525 29.8275 6.14265C26.6647 2.98006 22.3771 1.20036 17.9042 1.19367Z"
                      fill="#BADAFF"
                    />
                    <path
                      d="M13.8034 10.2153C13.8043 10.6619 13.6725 11.0988 13.4249 11.4705C13.1772 11.8423 12.8248 12.1322 12.4123 12.3035C11.9998 12.4748 11.5457 12.5198 11.1075 12.4329C10.6694 12.346 10.2669 12.131 9.95101 11.8151C9.63515 11.4993 9.42014 11.0968 9.3332 10.6587C9.24626 10.2206 9.2913 9.76648 9.46263 9.35397C9.63396 8.94146 9.92386 8.58907 10.2956 8.34144C10.6674 8.0938 11.1043 7.96206 11.551 7.96289C12.1483 7.96289 12.7213 8.2002 13.1437 8.6226C13.5661 9.045 13.8034 9.61789 13.8034 10.2153Z"
                      fill="#BADAFF"
                    />
                    <path
                      d="M13.3656 13.834H9.75488V25.4121H13.3656V13.834Z"
                      fill="#BADAFF"
                    />
                    <path
                      d="M26.7787 17.6636V17.5835C26.5931 15.8457 25.973 14.2513 24.058 13.7874H24.0158C24.0158 13.7874 20.384 12.7793 18.7558 15.7571V13.8295H15.2168V25.4077H18.9541V19.0218C18.9541 19.0218 19.0342 16.7947 21.2192 16.7905C21.9911 16.7905 22.9317 16.9845 23.1342 18.9332C23.1614 19.2136 23.174 19.4951 23.1722 19.7768V25.3739H26.8082V17.6763L26.7787 17.6636Z"
                      fill="#BADAFF"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="#youtube">
                  <svg fill="none" height="36" viewBox="0 0 36 36" width="36">
                    <path
                      d="M17.9253 35.9999C14.3799 35.9996 10.9143 34.9481 7.9665 32.9783C5.01873 31.0085 2.72124 28.209 1.36453 24.9336C0.00781252 21.6582 -0.347199 18.0541 0.344377 14.577C1.03595 11.0999 2.74306 7.90595 5.24985 5.39894C6.90731 3.7025 8.88494 2.3518 11.0682 1.4251C13.2514 0.4984 15.5968 0.014115 17.9686 0.000303978C20.3403 -0.013507 22.6912 0.44343 24.8851 1.34464C27.079 2.24586 29.0722 3.57343 30.7493 5.25045C32.4264 6.92747 33.754 8.9206 34.6553 11.1144C35.5565 13.3081 36.0135 15.6589 35.9997 18.0305C35.9859 20.4022 35.5016 22.7475 34.5748 24.9306C33.6481 27.1137 32.2973 29.0913 30.6008 30.7486C28.9402 32.4185 26.9648 33.7425 24.7891 34.6439C22.6133 35.5453 20.2804 36.0062 17.9253 35.9999ZM17.9253 1.19367C14.5883 1.19367 11.3261 2.18318 8.55147 4.03706C5.7768 5.89094 3.6142 8.52594 2.33716 11.6088C1.06012 14.6917 0.725989 18.0841 1.37702 21.3568C2.02805 24.6296 3.635 27.6359 5.99466 29.9954C8.35432 32.355 11.3607 33.9618 14.6337 34.6128C17.9066 35.2638 21.2991 34.9297 24.3821 33.6527C27.4652 32.3758 30.1003 30.2133 31.9543 27.4387C33.8083 24.6642 34.7978 21.4022 34.7978 18.0653C34.79 13.5931 33.0099 9.30625 29.8474 6.14389C26.6849 2.98153 22.3978 1.20148 17.9253 1.19367Z"
                      fill="#BADAFF"
                    />
                    <path
                      clipRule="evenodd"
                      d="M11.5367 12.2911C14.0929 12.1055 17.9272 12.1055 17.9272 12.1055L17.9356 12.1097C17.9356 12.1097 21.7699 12.1097 24.3261 12.2953C25.0139 12.3027 25.6713 12.5798 26.1567 13.0672C26.7009 13.6155 26.8823 14.8809 26.8823 14.8809C26.9949 15.8611 27.0555 16.8467 27.0636 17.8334V19.2211C27.0557 20.2078 26.9952 21.1934 26.8823 22.1736C26.8823 22.1736 26.7009 23.4348 26.1567 23.9873C25.6728 24.4774 25.0148 24.7563 24.3261 24.7634C21.7699 24.9532 17.9314 24.9532 17.9314 24.9532C17.9314 24.9532 13.1818 24.9111 11.7181 24.7719C11.6463 24.7577 11.5584 24.7468 11.4586 24.7344C10.9955 24.677 10.2751 24.5876 9.70604 23.9873C9.15768 23.439 8.9763 22.1736 8.9763 22.1736C8.86338 21.1934 8.80284 20.2078 8.79492 19.2211V17.8292C8.80309 16.8425 8.86363 15.8569 8.9763 14.8766C8.9763 14.8766 9.15768 13.6197 9.70604 13.0629C10.1907 12.5745 10.8486 12.2971 11.5367 12.2911ZM20.9666 18.3365L16.0314 20.901V15.7636L20.9666 18.3365Z"
                      fill="#BADAFF"
                      fillRule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-right">
            <h2>{t("contactForm")}</h2>
            <Formik
              initialValues={{
                name: "",
                lastName: "",
                email: "",
                message: "",
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit }) => (
                <Form>
                  <div className="form">
                    <div className="form-container">
                      <div className="form-left">
                        <Field
                          className="form-left-input"
                          name="name"
                          placeholder={t("name", { suffix: "*" })}
                        />
                        <Field
                          className="form-left-input"
                          name="lastName"
                          placeholder={t("lastName", { suffix: "*" })}
                        />
                        <Field
                          className="form-left-input"
                          name="email"
                          placeholder={t("email", { suffix: "*" })}
                        />
                      </div>
                      <Field
                        className="form-right-textarea"
                        component="textarea"
                        name="message"
                        placeholder={t("message", { suffix: "*" })}
                      />
                    </div>
                    <div>
                      <button className="form-button" type="submit">
                        <a>{t("send")}</a>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="privacy">
          <p>2022 ALL RIGHTS RESERVED AIDENTIS</p>
          <Link
            className="terms"
            to={PATH.generate(
              PATH.APP_PRIVACY_RULE,
              ECountryCode.GLOBAL,
              EPrivacyType.TERMS
            )}
          >
            {t("common:terms")}
          </Link>
          <Link
            className="policy"
            to={PATH.generate(
              PATH.APP_PRIVACY_RULE,
              ECountryCode.GLOBAL,
              EPrivacyType.PRIVACY_POLICY
            )}
          >
            {t("common:privacy-policy")}
          </Link>
          {/*<img alt="Visa Aidentis" src={visaIcon} style={{ width: "54px" }} />*/}
          {/*<img*/}
          {/*  alt="Mastercard Aidentis"*/}
          {/*  src={mastercardIcon}*/}
          {/*  style={{ width: "54px" }}*/}
          {/*/>*/}
        </div>
      </div>
    </PageFooterWrapper>
  );
};

export default PageFooter;
