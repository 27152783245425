export interface ITrgAnalysisType {
  id: 1;
  name: string;
}

export type TCaseType = "TRG" | "CBCT";
export type TCaseTab = "orthodontics" | "diagnostics";
export type TCaseFilterType = "ARCHIVE" | "FAVORITE";
export type TCaseTRGStatus =
  | "NEW"
  | "CALIBRATION"
  | "DIGITIZATION"
  | "DIGITIZATION_AI"
  | "READY";
export type TCaseCBCTStatus = "NOT_UPLOADED" | "UPLOADED" | "READY";
export type TPatientNationality = "AFRICAN" | "EUROPEAN" | "ASIAN" | "HISPANIC";

export interface IGetCasesResponseProps {
  content: ICase[];
  pageable: {
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface IGetCasesRequestProps {
  q?: string;
  caseTypes?: TCaseTab[];
  filters?: (string | number)[];
  page: number;
  limit?: number;
}

export interface ICase {
  calibrationPoints: { x: number; y: number }[];
  id: number;
  imagePath: string | null;
  patientLastName?: string | null;
  patientFirstName?: string | null;
  patientFullName: string | null;
  patientGender: string | null;
  patientAge: string | null;
  doctorFullName: string | null;
  patientNationality: TPatientNationality | null;
  caseType: TCaseTab;
  caseTRGStatus: TCaseTRGStatus;
  caseCBCTStatus: TCaseCBCTStatus;
  trgPixelLengthInMm: null;
  trgAnalysisType: ITrgAnalysisType;
  rubberLengthInMm: number | null;
  createdAt: string | null;
}

export interface IPostCaseCreateRequestProps {
  caseType: string;
  patientLastName: string;
  patientFirstName: string;
  patientGender: string;
  patientNationality?: TPatientNationality | null;
  patientAge: string;
  doctorFullName: string;
  trgAnalysisType?: number | string;
}

export enum ECaseType {
  CBCT = "CBCT",
  TRG = "TRG",
}

export enum ECaseView {
  CBCT = "CBCT",
  TRG = "TRG",
  BIOMETRICS = "biometrics",
  PHOTO_PROTOCOL = "photo-protocol",
  PANORAMIC_PHOTO = "panoramic-photo",
  QUESTIONNAIRE = "questionnaire",
}

export enum ECaseTab {
  ORTHODONTICS = "orthodontics",
  DIAGNOSTICS = "diagnostics",
}

export enum ECaseTRGStatus {
  NEW = "NEW",
  CALIBRATION = "CALIBRATION",
  DIGITIZATION = "DIGITIZATION",
  DIGITIZATION_AI = "DIGITIZATION_AI",
  READY = "READY",
}

export enum ECaseCBCTStatus {
  NOT_UPLOADED = "NOT_UPLOADED",
  UPLOADED = "UPLOADED",
  READY = "READY",
}

export interface IImg {
  name: string;
  image: string;
  size: number;
}
